<template>
	<div class="wechat">
		<div v-if="ll" class="wecontent center">
			<h2 class="weh2" v-html="ll.i6682" data-ll="6682"></h2>
			<img class="weimg" v-if="manager" :src="manager.img" :alt="manager.email_name" />

			<div v-if="manager" class="wecharname">{{manager.wechat}}</div>
			<div class="weline"></div>
			<div class="wetext" data-ll="6683" v-html="ll.i6683"></div>
			<img class="qrlink" v-if="qrlink" :src="qrlink" :alt="ll.i6684" />
		</div>
		<div class="blocksupport">
			<BlockRequest></BlockRequest>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */
	import axios from 'axios'
	import BlockRequest from '../components/BlockRequest';

	export default {
		name: 'Wechat',
		data: function() {
			return  {
				msg: "Wechat",
				ll: null,
				qrlink:null,
				manager:null
			}
		},
		components: {
			BlockRequest
		},
		watch:{
			lang(to, from){
				this.getll();
			}
		},
		methods: {
			async init(){
				this.route();
				await this.getll();
				await this.main();
			},
			async getll(){
				let res = await axios.post('/api/main/get', {datall:{"lang": this.lang,"controller": ["wechat"]} });
				if(res && res.data && res.data.datall) {
					this.ll = res.data.datall;
				}
			},
			async main(){
				let res = await axios.post('/api/main/get', {controller:[{name:"wechat"}, {name:"manager"}] });
				if(res && res.data ) {
					if(res.data.wechat && res.data.wechat.qr){
						this.qrlink = res.data.wechat.qr;
					}
					if(res.data.manager){
						this.manager = res.data.manager;
					}
				}
			},
			route(){
				if(this.$route.params.lang){
					if(this.$root.languages.includes(this.$route.params.lang)){
						if(this.$route.params.lang != this.$root.lang) {
							this.$root.lang = this.$route.params.lang;
						}
					}
					else{
						this.$router.push('/en/wechat');
						this.$router.push('/wechat');
						this.$root.lang = 'en';
					}
				}
			},
		},
		computed:{
			lang(){
				return this.$root.lang;
			}
		},
		mounted(){
			this.init();
		}
	}
</script>

<style lang="less">
	.wechat{
		width: 100%;
		overflow-x: hidden;
		line-height: normal;
		.weh2{
			margin: 55px 0 0 0;
			padding: 0;
			font-family: "Myriad Pro";
			font-size: 36px;
			color: #4C4C4C;
		}
		.weh2 span{
			color: #2CB742;
		}
		.wecontent{
			color: #4C4C4C;
			padding: 0 15px;
			margin-bottom: 40px;
		}
		.weimg{
			width: 110px;
			height: 110px;
			margin-top: 30px;
		}
		.wecharname{
			font-family: "Segoe UI", sans-serif;
			font-size: 30px;
			margin-top: 18px;
		}
		.weline{
			display: inline-block;
			width: 50px;
			height: 3px;
			background-color: #BBBBBB;
			margin-top: 18px;
		}
		.wetext{
			margin-top: 25px;
			font-family: "Segoe UI", sans-serif;
			font-size: 20px;
		}
		.qrlink{
			margin-top: 40px;
			margin-bottom: 20px;
			width: 180px;
			height: 180px;
		}

		@media(max-width: 640px){
			.weh2{
				margin: 40px 0 0 0;
				font-size: 28px;
			}
			.wecharname{
				font-size: 26px;
			}
			.wetext {
				font-size: 18px;
			}
			.qrlink {
				margin-bottom: -10px;
			}
		}
	}
	.center{
		text-align: center;
	}
</style>

